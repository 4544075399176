@tailwind base;
@tailwind components;
@tailwind utilities;




@layer components {

    .clubmate-card{
      @apply  p-3 rounded-lg bg-slate-300 shadow-sm
    }
    .list-item{
      @apply hover:opacity-50 flex   w-full justify-between relative p-3 my-[1.5px] rounded-xl hover:cursor-pointer bg-secondary 
    }
 
}
